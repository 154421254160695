import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom"; // useSearchParams 추가
import DetailLayout from "../../components/layout/DetailLayout";
import DetailList from "../../components/DetailList";
import categoryItems from "../../data/categoryItems";
import { ReadNormalBoardItem } from "../../types/ReadNormalBoardItem";
import { CircularProgress, Pagination } from "@mui/material";
import { AxiosController } from "../../api/axiosController";
import { ApiResponse } from "../../types/apiResponseTypes";

const Supplies = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams(); // 쿼리스트링을 다루기 위한 useSearchParams
  const [data, setData] = useState<ReadNormalBoardItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);

  // URL에서 페이지 번호를 가져옴. 없으면 1번 페이지로 설정
  const pageFromURL = parseInt(searchParams.get("page") || "1", 10);
  const [currentPage, setCurrentPage] = useState(pageFromURL);

  const controller = new AxiosController();

  const fetchData = async (page: number) => {
    try {
      const response = await controller.get({
        url: "Supplies",
        params: {
          PageNo: page,
        },
      });

      const resData = response as ApiResponse;

      if (resData.IsSuccess) {
        setData(resData.Message.ReadNormalBoardList);
        setTotalCount(resData.Message.TotalCount ?? 0);
      }
    } catch (error) {
      navigate("/Error");
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page);
    setSearchParams({ page: String(page) });
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    fetchData(currentPage);
  }, [currentPage]);

  return (
    <DetailLayout title="글램핑용품">
      <div className="flex justify-center w-full">
        {loading ? (
          <div>
            로딩 중...
            <CircularProgress size="2rem" />
          </div>
        ) : (
          <div className=" flex flex-col justify-center items-center w-full">
            <DetailList
              data={data}
              title={categoryItems["글램핑용품"].list[0]}
            />
            <Pagination
              count={Math.ceil(totalCount / 12)}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              variant="outlined"
              shape="rounded"
              sx={{ marginTop: "30px" }}
            />
          </div>
        )}
      </div>
    </DetailLayout>
  );
};

export default Supplies;
