const categoryItems = {
  회사소개: { title: "회사 소개", path: "/company" },
  글램핑텐트: {
    title: "글램핑 텐트",
    path: "/GlampingTent",
    list: ["정통형", "특수주문형 텐트", "설치사례"],
  },
  인디언게르텐트: {
    title: "인디언, 게르 텐트",
    path: "/Indian",
    list: ["인디언, 게르 텐트", "설치사례"],
  },
  카라반: {
    title: "카라반",
    path: "/Caravan",
    list: ["카라반"],
  },
  캠핑장부동산개발: {
    title: "캠핑장, 부동산개발",
    path: "/CampSite",
    list: ["캠핑장, 부동산개발"],
  },
  글램핑용품: {
    title: "글램핑용품",
    path: "/Supplies",
    list: ["글램핑 용품"],
  },
  설치사례: {
    title: "설치사례",
    path: "/Installation",
    list: ["글램핑텐트 설치사례", "인디언, 게르 텐트 설치사례"],
  },
  견적요청: {
    title: "견적요청",
    path: "/Board",
    list: ["견적요청"],
  },
};

export default categoryItems;
