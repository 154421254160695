import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { QueryClientProvider } from "@tanstack/react-query";
import "./index.css";
import Router from "./Router";
import { queryClient } from "./types/queryTypes";

const rootElement = document.getElementById("root");
if (!rootElement) {
  throw new Error("Root element not found");
}

const root = ReactDOM.createRoot(rootElement);

root.render(
  <HelmetProvider>
    <QueryClientProvider client={queryClient}>
      <Router />
    </QueryClientProvider>
  </HelmetProvider>
);
