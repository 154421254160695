// import { useEffect, useState } from "react";

// const KakaoMap = () => {
//   const [isScriptLoaded, setIsScriptLoaded] = useState(false);

//   useEffect(() => {
//     const scriptId = "roughmapLanderScript";

//     const loadScript = () => {
//       if (document.getElementById(scriptId)) {
//         setIsScriptLoaded(true);
//         return;
//       }

//       const protocol = location.protocol === "https:" ? "https:" : "http:";
//       const cdn = "16137cec";
//       const scriptSrc = `${protocol}//t1.daumcdn.net/kakaomapweb/place/jscss/roughmap/${cdn}/roughmapLander.js`;

//       const scriptTag = document.createElement("script");
//       scriptTag.id = scriptId;
//       scriptTag.src = scriptSrc;

//       scriptTag.onload = () => setIsScriptLoaded(true);
//       scriptTag.onerror = () =>
//         console.error("Failed to load the script:", scriptSrc);

//       document.body.appendChild(scriptTag);
//     };

//     loadScript();

//     return () => {
//       const existingScript = document.getElementById(scriptId);
//       if (existingScript) {
//         document.body.removeChild(existingScript);
//       }
//     };
//   }, []);

//   useEffect(() => {
//     if (isScriptLoaded && window.daum?.roughmap?.Lander) {
//       try {
//         new window.daum.roughmap.Lander({
//           timestamp: "1725432812931",
//           key: "2kjfg",
//           mapWidth: "100%",
//           mapHeight: "100%",
//         }).render();
//       } catch (error) {
//         console.error("Error creating roughmap:", error);
//       }
//     }
//   }, [isScriptLoaded]);

//   return (
//     <div
//       id="daumRoughmapContainer1725432812931"
//       className="root_daum_roughmap root_daum_roughmap_landing"
//       style={{ width: "90%", height: "400px" }}
//     ></div>
//   );
// };

// export default KakaoMap;

import { useEffect, useState } from "react";

const KakaoMap = () => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  useEffect(() => {
    const scriptId = "roughmapLanderScript";

    const loadScript = () => {
      if (document.getElementById(scriptId)) {
        setIsScriptLoaded(true);
        return;
      }

      const protocol = location.protocol === "https:" ? "https:" : "http:";
      const scriptSrc = `${protocol}//ssl.daumcdn.net/dmaps/map_js_init/roughmapLoader.js`;

      const scriptTag = document.createElement("script");
      scriptTag.id = scriptId;
      scriptTag.src = scriptSrc;

      scriptTag.onload = () => setIsScriptLoaded(true);
      scriptTag.onerror = () =>
        console.error("Failed to load the script:", scriptSrc);

      document.body.appendChild(scriptTag);
    };

    loadScript();

    return () => {
      const existingScript = document.getElementById(scriptId);
      if (existingScript) {
        document.body.removeChild(existingScript);
      }
    };
  }, []);

  useEffect(() => {
    if (isScriptLoaded && window.daum?.roughmap?.Lander) {
      try {
        new window.daum.roughmap.Lander({
          timestamp: "1728612850002", // 새로운 timestamp
          key: "2kuxi", // 새로운 key
          mapWidth: "100%", // 원하는 지도 너비
          mapHeight: "100%", // 원하는 지도 높이
        }).render();
      } catch (error) {
        console.error("Error creating roughmap:", error);
      }
    }
  }, [isScriptLoaded]);

  return (
    <div
      id="daumRoughmapContainer1728612850002" // 새로운 ID
      className="root_daum_roughmap root_daum_roughmap_landing"
      style={{ width: "640px", height: "360px" }} // 스타일을 정확히 지정
    ></div>
  );
};

export default KakaoMap;
