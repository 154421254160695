import React from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/logo/removeWhite.png";

const Footer: React.FC = () => {
  const navigate = useNavigate();

  return (
    <footer className="bg-[#F1F5F9] border-t-4 border-blue-200 text-primary-foreground py-4 px-6 flex items-center gap-2 justify-center">
      <img
        src={Logo}
        alt="Logo"
        className="w-48 h-20 cursor-pointer"
        onClick={() => navigate("/")}
      />
      <div className="flex flex-col items-start gap-1 text-start text-black">
        <div className="flex gap-2 flex-wrap justify-center xs:justify-start sm:justify-start">
          <p
            key={"회사소개"}
            className="hover:underline text-xs xs:text-xs sm:text-xs md:text-lg"
            onClick={() => navigate("/Company")}
          >
            회사 소개
          </p>
        </div>

        <p className="text-xxs xs:text-xxs sm:text-xxs md:text-sm gmarketLight">
          미즈캠핑자연인 대표 : 김선희 | 사업자등록번호 : 446-87-02715 | Tel :
          010-4157-3478 | E-mail. korwhites@naver.com
        </p>
        <p className="text-xxs xs:text-xxs sm:text-xxs md:text-sm gmarketLight">
          주소 : 경기도 파주시 산남로 107번길 33-5 (주)미즈캠핑자연인
        </p>
        <p className="text-xxs xs:text-xxs sm:text-xxs md:text-sm gmarketLight">
          &copy; COPYRIGHT (C) AuroraCode. ALL RIGHT RESERVED
        </p>
      </div>
    </footer>
  );
};

export default Footer;
