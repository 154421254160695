import React, { useEffect } from "react";
import "../globals.css";
import SideBar from "../components/SideBar";
import PhotoSlider from "../components/home/PhotoSlider";
import Info from "../components/home/Info";
import ScrollTopButton from "../components/ScrollTopButton";

const Home: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className="flex flex-col">
      <div>
        <PhotoSlider />
      </div>
      <div className="">
        <SideBar />
        <ScrollTopButton />
      </div>
      <div className="w-[80%] mx-auto mt-6">
        <Info />
      </div>
    </div>
  );
};

export default Home;
