const personalInfo = `(주)미즈캠핑 자연인(이하 '회사')은 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다.

회사는 개인정보처리방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.

1. 개인정보 수집 및 이용 목적
   - 문의, 예약, 주문 등 민원사항 처리 및 고객요청 처리
   - 서비스 제공 및 계약 이행을 위한 연락, 고지사항 전달
   - 이용자 식별 및 본인여부 확인
   - 분쟁 조정을 위한 기록 보존

2. 수집하는 개인정보의 항목
   - 필수항목: 이름, 이메일, 전화번호

3. 개인정보의 보유 및 이용기간
   회사는 이용자의 개인정보를 수집 및 이용 목적, 이용 기간에만 제한적으로 이용하며, 동의를 철회하는 경우 지체 없이 파기합니다. 다만 관계법령에 의해 보관해야 하는 정보는 법령이 정한 기간 동안 보관됩니다.
   - 표시/광고에 관한 기록: 6개월 (전자상거래 등에서의 소비자보호에 관한 법률)
   - 계약 또는 청약철회 등에 관한 기록: 5년 (전자상거래 등에서의 소비자보호에 관한 법률)
   - 대금결제 및 재화 등의 공급에 관한 기록: 5년 (전자상거래 등에서의 소비자보호에 관한 법률)
   - 소비자의 불만 또는 분쟁처리에 관한 기록: 3년 (전자상거래 등에서의 소비자보호에 관한 법률)
   - 기타, 이용자의 개별적인 동의가 있는 경우에는 개별 동의에 따른 기간까지 보관합니다.

4. 개인정보의 제3자 제공에 관한 사항
   회사는 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.

5. 개인정보처리 위탁
   회사는 고객님의 동의 없이 고객님의 정보를 외부 업체에 위탁하지 않습니다. 향후 그러한 필요가 생길 경우, 위탁 대상자와 위탁 업무 내용에 대해 고객님에게 통지하고 필요한 경우 사전 동의를 받도록 하겠습니다.

6. 정보주체와 법정대리인의 권리·의무 및 그 행사방법
   이용자는 개인정보주체로써 다음과 같은 권리를 행사할 수 있습니다.
   - 개인정보 열람, 정정, 삭제, 처리정지 요구
   - 권리 행사는 서면, 전자우편, 모사전송(FAX) 등을 통해 가능
   - 법정대리인이나 위임을 받은 자 등 대리인을 통한 권리 행사 가능 (위임장 제출 필요)
   - 개인정보 열람 및 처리정지 요구는 관련 법에 의해 제한될 수 있음
   - 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시된 경우 제한될 수 있음

7. 처리하는 개인정보의 항목
   - 필수항목: 이메일, 이름, 휴대전화번호

8. 개인정보의 파기
   회사는 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체없이 해당 개인정보를 파기합니다.
   - 파기절차: 목적 달성 후 별도 DB로 옮겨져 내부 방침 및 관련 법령에 따라 일정기간 저장 후 파기
   - 파기기한: 보유기간 종료일로부터 5일 이내 또는 개인정보 처리가 불필요하게 된 날로부터 5일 이내
   - 파기방법: 전자적 파일은 복구 불가능한 방법으로 파기, 종이 문서는 분쇄 또는 소각

9. 개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항
   회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 '쿠키(cookie)'를 사용합니다.
   - 쿠키 사용 목적: 이용자의 방문 및 이용형태, 보안접속 여부 등 파악
   - 쿠키 설치•운영 및 거부: 웹브라우저 옵션 설정을 통해 쿠키 저장 거부 가능
   - 쿠키 저장 거부 시 맞춤형 서비스 이용에 어려움이 발생할 수 있음

10. 개인정보 보호책임자
    - 책임자: 이승목
    - 연락처: 010-4157-3478

11. 개인정보의 안전성 확보 조치
    회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다:
    - 관리적 조치: 내부관리계획 수립·시행, 정기적 직원 교육
    - 기술적 조치: 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 고유식별정보 등의 암호화, 보안프로그램 설치
    - 물리적 조치: 전산실, 자료보관실 등의 접근통제

12. 개인정보 침해 관련 상담 및 신고
    개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
    - 개인정보침해신고센터 (privacy.kisa.or.kr / 국번없이 118)
    - 대검찰청 사이버수사과 (www.spo.go.kr / 국번없이 1301)
    - 경찰청 사이버안전국 (cyberbureau.police.go.kr / 국번없이 182)

13. 개인정보 처리방침 변경
    이 개인정보 처리방침은 2024년 10월 30일부터 적용됩니다. 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다.

※ 서비스 제공을 위해 필요한 최소한의 개인정보이므로 동의를 해주셔야 서비스를 이용하실 수 있습니다.`;

export default personalInfo;
