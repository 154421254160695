import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./components/layout/Layout";
import Home from "./pages/Home";
import Company from "./pages/Company";
import GlampingTent from "./pages/glampingTent/GlampingTent";
import GlampingTentlegitimacyDetail from "./pages/glampingTent/GlampingTentlegitimacyDetail";
import GlampingTentSpecialorderDetail from "./pages/glampingTent/GlampingTentSpecialorderDetail";
import Indian from "./pages/indian/Indian";
import IndianDetail from "./pages/indian/IndianDetail";
import Caravan from "./pages/caravan/Caravan";
import CaravanDetail from "./pages/caravan/CaravanDetail";
import CampSite from "./pages/campSite/CampSite";
import CampSiteDetail from "./pages/campSite/CampSiteDetail";
import Supplies from "./pages/supplies/Supplies";
import SuppliesDetail from "./pages/supplies/SuppliesDetail";
import Installation from "./pages/installation/Installation";
import GlampingInstallationDetail from "./pages/installation/GlampingTentInstallationDetail";
import IndianInstallationDetail from "./pages/installation/IndianInstallationDetail";
import Board from "./pages/board/Board";
import ErrorPage from "./pages/Error";

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          {/* 홈 */}
          <Route path="/" element={<Home />} />

          {/* 회사소개 */}
          <Route path="/Company" element={<Company />} />

          {/* 글램핑텐트 */}
          <Route path="/GlampingTentlegitimacy" element={<GlampingTent />} />
          <Route
            path="/GlampingTentlegitimacy/:PostId"
            element={<GlampingTentlegitimacyDetail />}
          />
          <Route
            path="/GlampingTentSpecialorder/:PostId"
            element={<GlampingTentSpecialorderDetail />}
          />

          {/* 인디언, 게르텐트 */}
          <Route path="/Indian" element={<Indian />} />
          <Route path="/Indian/:PostId" element={<IndianDetail />} />

          {/* 카라반 */}
          <Route path="/Caravan" element={<Caravan />} />
          <Route path="/Caravan/:PostId" element={<CaravanDetail />} />

          {/* 캠핑장, 부동산 개발 */}
          <Route path="/CampSite" element={<CampSite />} />
          <Route path="/CampSite/:PostId" element={<CampSiteDetail />} />

          {/* 글램핑용품 */}
          <Route path="/Supplies" element={<Supplies />} />
          <Route path="/Supplies/:PostId" element={<SuppliesDetail />} />

          {/* 설치사례 */}
          <Route path="/Installation/:Category" element={<Installation />} />
          <Route
            path="/GlampingTentInstallation/:PostId"
            element={<GlampingInstallationDetail />}
          />
          <Route
            path="/IndianInstallation/:PostId"
            element={<IndianInstallationDetail />}
          />

          {/* 견적요청 */}
          <Route path="/Board" element={<Board />} />

          {/* 에러 페이지 */}
          <Route path="/Error" element={<ErrorPage />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
};

export default Router;
