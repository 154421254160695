import React, { useState, useEffect } from "react";
import SlideshowRoundedIcon from "@mui/icons-material/SlideshowRounded";
import WhereToVoteOutlinedIcon from "@mui/icons-material/WhereToVoteOutlined";
import blog from "../assets/blog.png";
import CallIcon from "@mui/icons-material/Call";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import CallModal from "./CallModal";

interface SideBarItem {
  icon: React.ReactNode;
  text: string;
  onClick?: () => void;
}

const SideBar: React.FC = () => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  // 사이드바 상태를 localStorage에서 불러옴
  useEffect(() => {
    const savedState = localStorage.getItem("sidebar-open");
    setIsOpen(savedState === "true");
  }, []);

  // 사이드바 토글 함수
  const toggleSidebar = () => {
    setIsOpen((prev) => {
      const newState = !prev;
      localStorage.setItem("sidebar-open", JSON.stringify(newState)); // 새로운 상태를 localStorage에 저장
      return newState;
    });
  };

  const items: SideBarItem[] = [
    {
      icon: (
        <img
          src={blog}
          alt="Blog"
          className="flex items-center justify-center h-7 object-contain"
        />
      ),
      text: "블로그",
      onClick: () =>
        window.open("https://blog.naver.com/newsapjilman", "_blank"),
    },
    {
      icon: <SlideshowRoundedIcon sx={{ fontSize: 32, color: "#FF0000" }} />,
      text: "유튜브",
      onClick: () =>
        window.open("https://www.youtube.com/@user-hw9yj8wy6f", "_blank"),
    },
    {
      icon: <WhereToVoteOutlinedIcon sx={{ fontSize: 32, color: "#0F378D" }} />,
      text: "오시는 길",
      onClick: () => {
        navigate("/Company");
        setTimeout(() => {
          const addressElement = document.getElementById("address");
          if (addressElement) {
            addressElement.scrollIntoView({ behavior: "smooth" });
          }
        }, 10);
      },
    },
    {
      icon: <CallIcon sx={{ fontSize: 32, color: "#0F378D" }} />,
      text: "상담 문의 전화",
      onClick: () => setModalOpen(true),
    },
  ];

  return (
    <>
      {/* Sidebar */}
      <div
        className={`fixed top-1/2 transform -translate-y-1/2 bg-white z-50 shadow-lg rounded-xl  ${
          isOpen ? "right-0 w-16 md:w-52" : "-right-64 w-0"
        }`}
      >
        {/* Sidebar Items */}
        {isOpen && (
          <div>
            {items.map((item: SideBarItem, index: number) => (
              <div
                key={index}
                className="flex items-center justify-start gap-4 p-4 cursor-pointer transition-colors hover:bg-gray-200 border-b border-gray-300 last:border-b-0 rounded-xl"
                onClick={item.onClick}
              >
                <div className="text-xl md:text-2xl lg:text-3xl object-contain">
                  {item.icon}
                </div>
                <div className="hidden md:inline text-md lg:text-lg">
                  {item.text}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Toggle Button */}
      <button
        onClick={toggleSidebar}
        className={`fixed top-1/2 transform -translate-y-1/2 z-50 p-0.5 lg:p-1.5 rounded-full shadow-lg bg-white 
          ${isOpen ? "right-16 md:right-52" : "right-5 md:right-5"}
        `}
      >
        {isOpen ? (
          <ArrowForwardIosIcon fontSize="small" /> // Close Icon
        ) : (
          <ArrowBackIosNewIcon fontSize="small" /> // Open Icon
        )}
      </button>

      {/* Call Modal */}
      <CallModal open={modalOpen} handleClose={() => setModalOpen(false)} />
    </>
  );
};

export default SideBar;
