import { useNavigate, useParams } from "react-router-dom";
import DetailLayout from "../../components/layout/DetailLayout";
import DetailView from "../../components/DetailView";
import SideBar from "../../components/SideBar";
import { useDetailQuery } from "../../hooks/useDetailQuery";
import { ReadNormalBoardItem } from "../../types/ReadNormalBoardItem";
import { useEffect } from "react";

const GlampingInstallationDetail = () => {
  const navigate = useNavigate();
  const { PostId } = useParams<{ PostId: string }>();

  const { data, isLoading, isError } = useDetailQuery<ReadNormalBoardItem>(
    "GlampingTentInstallation",
    PostId ?? ""
  );

  // 페이지가 로드될 때 맨 위로 스크롤
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <DetailLayout title="설치사례">
      <div className="flex flex-col w-full justify-center items-center">
        {!PostId || isError ? (
          <div>데이터를 불러올 수 없습니다.</div>
        ) : isLoading ? (
          <div>로딩 중...</div>
        ) : data ? (
          <>
            <SideBar />
            <DetailView data={data} />
            <button
              onClick={() => navigate(-1)}
              className="mt-4 p-2 w-[20%] justify-start items-start bg-blue-400 text-white rounded text-base sm:text-lg"
            >
              목록보기
            </button>
          </>
        ) : (
          <div>데이터가 없습니다.</div>
        )}
      </div>
    </DetailLayout>
  );
};

export default GlampingInstallationDetail;
