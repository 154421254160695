import React, { useEffect } from "react";
import info from "../assets/sub1.jpg";
import KakaoMap from "../components/KakaoMap";
import DetailLayout from "../components/layout/DetailLayout";

const Company: React.FC = () => {
  const copyAddress = async () => {
    const address = "경기도 파주시 산남로 107번길 33-5";
    try {
      await navigator.clipboard.writeText(address);
      alert("주소가 복사되었습니다: " + address);
    } catch (err) {
      console.error("주소 복사 실패:", err);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <DetailLayout title="회사 소개" img={""}>
      <div className="flex flex-col w-[80%]">
        <div className="flex flex-col p-10 xs:p-0">
          <p className="text-black text-xl sm:text-xl md:text-2xl lg:text-3xl font-bold pb-4 border-b-2 border-blue-300 ">
            인사말
          </p>
          <img src={info} alt="info" className="rounded-lg shadow-lg mt-4" />
          <div className="flex flex-col p-6 shadow-lg text-lg xxs:text-sm xs:text-base sm:text-base lg:text-lg">
            <p className="text-gray-700 mt-4 leading-relaxed">
              미즈캠핑 자연인은 2014년 7월 1일 오픈한 글램핑 텐트 주문.제작.설치
              회사입니다.
            </p>
            <p className="text-gray-700 mt-4 leading-relaxed">
              미즈캠핑 자연인의 글램핑 텐트는 철 구조물과 이너텐트
            </p>
            <p className="text-gray-700 mt-4 leading-relaxed">
              모두 본사가 특수 설계 하여 특허청에 심사 등록된 디자인으로
              설치하고 있으며,
            </p>
            <p className="text-gray-700 mt-4 leading-relaxed">
              방염. 방수. 곰팡이에 강한 향균성 이너텐트와 타프를 사용하고
              있습니다.
            </p>
            <p className="text-gray-700 mt-4 leading-relaxed">
              글램핑 캠핑장의 특수상황을 고려하여 맞춤형 텐트를 주문 제작하는
            </p>
            <p className="text-gray-700 mt-4 leading-relaxed">
              성실 시공의 정신으로 임직원 모두 최선을 다하는 기업입니다.
            </p>
          </div>
        </div>

        <div
          id="address"
          className="flex flex-col p-12 xxs:p-0 xxs:pt-10 xs:p-0 xs:pt-12"
        >
          <p className="text-black text-xl sm:text-xl md:text-2xl lg:text-3xl font-bold pb-4 border-b-2 border-blue-300 w-full">
            오시는 길
          </p>
          <div className="flex flex-row justify-end items-center  mb-2 mt-4">
            <p
              className="text-gray-700 text-sm sm:text-lg "
              onClick={copyAddress}
            >
              주소 : 경기도 파주시 산남로 107번길 33-5
            </p>
            <button
              className="ml-2 p-0.5 text-sm sm:text-lg rounded-sm border-blue-300 border-2"
              onClick={copyAddress}
            >
              복사
            </button>
          </div>

          <p className="text-gray-700 text-lg flex justify-end">
            (주) 미즈캠핑자연인
          </p>
          <div className="flex justify-center pt-8">
            <KakaoMap />
          </div>
        </div>
      </div>
    </DetailLayout>
  );
};

export default Company;
