const menuItems = [
  { label: "회사소개", path: "/Company" },
  { label: "글램핑텐트", path: "/GlampingTentlegitimacy" },
  { label: "인디언,게르텐트", path: "/Indian" },
  { label: "카라반", path: "/Caravan" },
  { label: "캠핑장,부동산개발", path: "/CampSite" },
  { label: "글램핑용품", path: "/Supplies" },
  { label: "설치사례", path: "/Installation/글램핑텐트 설치사례" },
  { label: "견적요청", path: "/board" },
];

export default menuItems;
