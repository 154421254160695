import axios from "axios";

// Axios 인스턴스를 생성하고 공통 설정을 추가
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_READNORMALBOARD_API_URL,
  timeout: 5000, // 요청 제한 시간을 설정 (5초)
  headers: {
    "Content-Type": "application/json",
  },
});

// 요청 인터셉터
axiosInstance.interceptors.request.use(
  (config) => {
    const code = process.env.REACT_APP_API_CODE;

    if (code && typeof code === "string") {
      if (typeof config.url === "string") {
        // baseURL에 url을 붙여 전체 URL을 생성
        const fullUrl = `${config.baseURL}${config.url}`;

        // 쿼리 파라미터 추가
        const urlWithParams = new URL(fullUrl);
        const params = new URLSearchParams(urlWithParams.search);

        // code 추가
        params.append("code", code);

        // 쿼리 파라미터를 업데이트
        urlWithParams.search = params.toString();

        config.url = urlWithParams.toString();
      } else {
        console.warn("config.url is not a string:", config.url);
      }
    } else {
      console.warn("API Code is not defined or is not a string.");
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 응답 인터셉터
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.error("API Error:", error);
    return Promise.reject(error);
  }
);

export default axiosInstance;
