// import { useEffect, useState } from "react";
// import { ReadNormalBoardItem } from "../../types/ReadNormalBoardItem";
// import { useNavigate, useParams } from "react-router-dom";
// import { AxiosController } from "../../api/axiosController";
// import { ApiResponse } from "../../types/apiResponseTypes";
// import DetailLayout from "../../components/layout/DetailLayout";
// import categoryItems from "../../data/categoryItems";
// import { CircularProgress, Pagination } from "@mui/material";
// import DetailList from "../../components/DetailList";

// const Installation = () => {
//   const navigate = useNavigate();
//   const { Category } = useParams<{ Category: string }>();
//   const [data, setData] = useState<ReadNormalBoardItem[]>([]);
//   const [loading, setLoading] = useState(true);
//   const [activeIndex, setActiveIndex] = useState<number>(
//     Category === "글램핑텐트 설치사례" ? 0 : 1
//   );
//   const [totalCount, setTotalCount] = useState(0);
//   const [currentPage, setCurrentPage] = useState(1);

//   const controller = new AxiosController();

//   // 데이터 요청 함수
//   const fetchData = async (item: string, page: number) => {
//     setLoading(true);

//     try {
//       const url =
//         item === "글램핑텐트 설치사례"
//           ? "GlampingTentInstallation"
//           : "IndianInstallation";

//       const response = await controller.get({
//         url,
//         params: {
//           PageNo: page,
//         },
//       });

//       const resData = response as ApiResponse;

//       if (resData.IsSuccess) {
//         setData(resData.Message.ReadNormalBoardList);
//         setTotalCount(resData.Message.TotalCount ?? 0);
//       }
//     } catch (error) {
//       navigate(`/Error`);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleCategoryChange = (item: string, index: number) => {
//     setActiveIndex(index);

//     setCurrentPage(1); // 카테고리 변경 시 첫 페이지로 초기화

//     navigate(`/Installation/${item}`);
//   };

//   const handlePageChange = (page: number) => {
//     setCurrentPage(page);
//   };

//   // 카테고리나 페이지가 변경될 때 데이터 요청
//   useEffect(() => {
//     const index = categoryItems["설치사례"].list.indexOf(
//       Category ?? "글램핑텐트 설치사례"
//     );

//     setActiveIndex(index !== -1 ? index : 0);

//     fetchData(Category ?? "글램핑텐트 설치사례", currentPage);
//   }, [Category, currentPage]);

//   return (
//     <DetailLayout title="설치사례">
//       <div className="absolute top-[330px] w-full flex justify-center items-center z-40 rounded-lg pt-2 xxs:h-[90px] xs:h-[90px] sm:h-[90px] md:h-[100px] lg:h-[100px]">
//         <div className="flex flex-row w-[70%] h-full text-center rounded-lg justify-evenly items-center xxs:text-xs xs:text-xs sm:text-sm md:text-md lg:text-xl">
//           {categoryItems["설치사례"].list.map((item: string, index: number) => (
//             <p
//               key={index}
//               className={`flex text-center rounded-2xl border-gray-300 h-full justify-center items-center shadow-lg xxs:text-lg xs:text-lg sm:text-xl md:text-xl lg:text-2xl ${
//                 activeIndex === index
//                   ? "text-black bg-blue-200 w-[50%]"
//                   : "bg-white rounded-lg w-[50%] hover:bg-blue-200"
//               }`}
//               onClick={() => handleCategoryChange(item, index)}
//             >
//               {item}
//             </p>
//           ))}
//         </div>
//       </div>
//       <div className="flex justify-center w-full">
//         {loading ? (
//           <div>
//             로딩 중...
//             <CircularProgress size="2rem" />
//           </div>
//         ) : data.length > 0 ? (
//           <div className=" flex flex-col justify-center items-center">
//             <DetailList
//               data={data}
//               title={categoryItems["설치사례"].list[activeIndex]}
//             />
//             <Pagination
//               count={Math.ceil(totalCount / 12)}
//               page={currentPage}
//               onChange={(event, page) => handlePageChange(page)}
//               color="primary"
//               variant="outlined"
//               shape="rounded"
//               sx={{ marginTop: "30px" }}
//             />
//           </div>
//         ) : (
//           <p>데이터가 없습니다.</p>
//         )}
//       </div>
//     </DetailLayout>
//   );
// };

// export default Installation;

import { useEffect, useState } from "react";
import { ReadNormalBoardItem } from "../../types/ReadNormalBoardItem";
import { useNavigate, useParams, useSearchParams } from "react-router-dom"; // useSearchParams 추가
import { AxiosController } from "../../api/axiosController";
import { ApiResponse } from "../../types/apiResponseTypes";
import DetailLayout from "../../components/layout/DetailLayout";
import categoryItems from "../../data/categoryItems";
import { CircularProgress, Pagination } from "@mui/material";
import DetailList from "../../components/DetailList";

const Installation = () => {
  const navigate = useNavigate();
  const { Category } = useParams<{ Category: string }>();
  const [searchParams, setSearchParams] = useSearchParams(); // 쿼리스트링을 다루기 위한 useSearchParams
  const [data, setData] = useState<ReadNormalBoardItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState<number>(
    Category === "글램핑텐트 설치사례" ? 0 : 1
  );
  const [totalCount, setTotalCount] = useState(0);

  // URL에서 페이지 번호를 가져옴. 없으면 1번 페이지로 설정
  const pageFromURL = parseInt(searchParams.get("page") || "1", 10);
  const [currentPage, setCurrentPage] = useState(pageFromURL);

  const controller = new AxiosController();

  // 데이터 요청 함수
  const fetchData = async (item: string, page: number) => {
    setLoading(true);

    try {
      const url =
        item === "글램핑텐트 설치사례"
          ? "GlampingTentInstallation"
          : "IndianInstallation";

      const response = await controller.get({
        url,
        params: {
          PageNo: page,
        },
      });

      const resData = response as ApiResponse;

      if (resData.IsSuccess) {
        setData(resData.Message.ReadNormalBoardList);
        setTotalCount(resData.Message.TotalCount ?? 0);
      }
    } catch (error) {
      navigate(`/Error`);
    } finally {
      setLoading(false);
    }
  };

  const handleCategoryChange = (item: string, index: number) => {
    setActiveIndex(index);

    setCurrentPage(1); // 카테고리 변경 시 첫 페이지로 초기화
    setSearchParams({ page: "1" }); // URL 쿼리스트링에도 반영

    navigate(`/Installation/${item}`);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page);
    setSearchParams({ page: String(page) }); // 페이지 변경 시 URL 쿼리스트링에도 반영
  };

  // 카테고리나 페이지가 변경될 때 데이터 요청
  useEffect(() => {
    window.scrollTo(0, 0);

    const index = categoryItems["설치사례"].list.indexOf(
      Category ?? "글램핑텐트 설치사례"
    );

    setActiveIndex(index !== -1 ? index : 0);

    fetchData(Category ?? "글램핑텐트 설치사례", currentPage);
  }, [Category, currentPage]);

  return (
    <DetailLayout title="설치사례">
      <div className="relative top-[-140px] w-full flex justify-center items-center z-40 rounded-lg pt-2 xxs:h-[90px] xs:h-[90px] sm:h-[90px] md:h-[100px] lg:h-[100px]">
        <div className="flex flex-row w-[70%] h-full text-center rounded-lg justify-evenly items-center xxs:text-xs xs:text-xs sm:text-sm md:text-md lg:text-xl">
          {categoryItems["설치사례"].list.map((item: string, index: number) => (
            <p
              key={index}
              className={`flex text-center rounded-2xl border-gray-300 h-full justify-center items-center shadow-lg text-base xs:text-sm sm:text-lg md:text-xl lg:text-2xl ${
                activeIndex === index
                  ? "text-black bg-blue-200 w-[50%]"
                  : "bg-white rounded-lg w-[50%] hover:bg-blue-200"
              }`}
              onClick={() => handleCategoryChange(item, index)}
            >
              {item}
            </p>
          ))}
        </div>
      </div>
      <div className="flex justify-center w-full">
        {loading ? (
          <div>
            로딩 중...
            <CircularProgress size="2rem" />
          </div>
        ) : (
          <div className=" flex flex-col relative top-[-100px] justify-center items-center w-full">
            <DetailList
              data={data}
              title={categoryItems["설치사례"].list[activeIndex]}
            />
            <Pagination
              count={Math.ceil(totalCount / 12)}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              variant="outlined"
              shape="rounded"
              sx={{ marginTop: "30px" }}
            />
          </div>
        )}
      </div>
    </DetailLayout>
  );
};

export default Installation;
