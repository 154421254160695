import React from "react";
import { useNavigate } from "react-router-dom";
import { ReadNormalBoardItem } from "../types/ReadNormalBoardItem";

interface DetailListProps {
  data: ReadNormalBoardItem[];
  title: string;
}

const DetailList: React.FC<DetailListProps> = ({ data, title }) => {
  const navigate = useNavigate();
  const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  return (
    <div className="flex flex-col w-[95%] bg-white p-10 shadow-lg rounded-lg border-4 border-blue-200">
      <p className="text-black font-bold pl-4 pb-4 border-b-4 border-blue-300 w-full text-xl sm:text-xl md:text-2xl lg:text-3xl">
        {title}
      </p>

      {data.length === 0 ? (
        <p className="text-center text-lg text-gray-500 mt-4">
          데이터가 없습니다.
        </p>
      ) : (
        <div className="grid  grid-cols-1 gap-4 pt-4 sm:grid-cols-2 xl:grid-cols-3">
          {data.map((item) => {
            return (
              <div
                key={item.RowKey}
                className="p-3 rounded-lg shadow-lg cursor-pointer w-auto h-[240px] sm:h-[260px] md:h-[200px] lg:h-[260px] xl:min-h-[300px]"
                onClick={() => navigate(`/${item.PartitionKey}/${item.RowKey}`)}
              >
                <img
                  src={`${imageBaseUrl}/${item.ImgList[0]}`}
                  alt={`${item.Title}`}
                  className="w-full h-3/4 object-fill rounded-lg p-2"
                />
                <p
                  className={`flex text-black text-center text-base h-1/4 justify-center items-center ${
                    item.Title.length > 12
                      ? "xs:text-sm sm:text-sm md:text-base lg:text-xl xl:text-2xl"
                      : "w-full md:text-lg lg:text-2xl xl:text-2xl"
                  }`}
                >
                  {item.Title}
                </p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default DetailList;
