import { AxiosError, AxiosRequestConfig } from "axios";
import axiosInstance from "./axiosInstance";
import { ApiResponse } from "../types/apiResponseTypes";

// IAxiosParams 타입에 params 속성 추가
type IAxiosParams = {
  url?: string;
  options?: AxiosRequestConfig;
  body?: unknown;
  params?: { [key: string]: any };
  endpoint?: string;
};

export class AxiosController {
  private axiosInstance = axiosInstance;

  // baseURL을 동적으로 업데이트할 수 있는 메서드
  updateBaseURL(newURL: string) {
    this.axiosInstance.defaults.baseURL = newURL;
  }

  // GET 요청 메서드
  async get<D>({ url = "/", options = {}, params }: IAxiosParams) {
    try {
      const data = await this.axiosInstance.get<D>(url, {
        ...options,
        params,
      });
      return data.data;
    } catch (error) {
      console.error("API 요청 실패:", error); // 에러 로그 출력
      throw error; // 에러를 다시 던져 호출하는 곳에서 처리할 수 있도록
    }
  }

  // POST 요청 메서드
  async post<D>({ url = "/", body = {}, options = {} }: IAxiosParams) {
    try {
      const data = await this.axiosInstance.post<D>(url, body, {
        ...options,
      });
      return data.data; // data.data를 반환
    } catch (error) {
      return this.onError(error as AxiosError<{ message: string | undefined }>);
    }
  }

  // 에러 핸들링 메서드
  private onError({ response }: AxiosError<{ message: string | undefined }>) {
    console.error(
      `API 에러 발생: status ${response?.status}\nmessage ${response?.data?.message}`
    );
    return { message: response?.data?.message };
  }

  // 통합된 API 요청 메서드
  async fetchData(endpoint: string, page: number): Promise<ApiResponse> {
    return this.get<ApiResponse>({
      url: endpoint,
      params: { PageNo: page },
    });
  }

  async fetchDetailData(
    endpoint: string,
    page: number,
    PostId: string
  ): Promise<ApiResponse> {
    return this.get<ApiResponse>({
      url: endpoint,
      params: { PageNo: page, PostId: PostId },
    });
  }
}
