import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/logo/removeWhite.png";
import Drawer from "@mui/material/Drawer";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import menuItems from "../../data/menuItems";

const MenuBar: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  // Drawer 열기/닫기 토글 함수
  const toggleDrawer = () => setOpen((prev) => !prev);

  // 경로를 변경하고 Drawer를 닫는 함수
  const handleNavigation = (path: string) => {
    navigate(path);
    setOpen(false);
  };

  return (
    <div className="flex justify-center bg-[#F1F5F9] border-b-4 border-blue-200">
      <header className="flex flex-row w-full h-24 items-center lg:w-[100%] ml-[8%] mr-[10%] md:w-[70%] xl:mr-[5%] justify-between">
        {/* <div className="flex flex-row w-full h-24 items-center"> */}
        <div className="flex justify-start items-center">
          <img
            src={Logo}
            alt="Logo"
            className="w-48 h-20 cursor-pointer"
            onClick={() => navigate("/")}
          />
        </div>

        <div className="lg:hidden xl:hidden flex items-center h-full">
          <IconButton onClick={toggleDrawer}>
            {open ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
        </div>

        {/* 데스크탑 및 태블릿 메뉴 */}
        <nav className="hidden lg:flex flex-row text-[#213260] text-base lg:text-base xl:text-xl items-center lg:gap-3 xl:gap-8">
          {menuItems.map(({ label, path }) => (
            <button
              key={path}
              onClick={() => navigate(path)}
              className="hover:underline"
            >
              {label}
            </button>
          ))}
        </nav>
        {/* </div> */}

        {/* 모바일 메뉴 - Drawer */}
        <Drawer
          anchor="right"
          open={open}
          onClose={toggleDrawer}
          sx={{
            "& .MuiDrawer-paper": {
              height: "100%",
            },
          }}
        >
          <div className="flex flex-col p-4 h-full sm:w-60 ">
            <div className="flex justify-end mb-4">
              <IconButton onClick={toggleDrawer}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className="flex flex-col gap-4">
              {menuItems.map(({ label, path }) => (
                <MenuItem
                  key={path}
                  sx={{
                    fontSize: {
                      xs: "17px",
                      sm: "20px",
                      md: "20px",
                      lg: "23px",
                    },
                  }}
                  onClick={() => handleNavigation(path)}
                >
                  {label}
                </MenuItem>
              ))}
            </div>
          </div>
        </Drawer>
      </header>
    </div>
  );
};

export default MenuBar;
