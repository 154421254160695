import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { useEffect } from "react";
import { AxiosController } from "../api/axiosController";
import { ApiResponse } from "../types/apiResponseTypes";
import { ReadNormalBoardItem } from "../types/ReadNormalBoardItem";

const controller = new AxiosController();
const cacheName = "DetailQuery";
const cacheExpiryTime = 30 * 60 * 1000; // 캐시 만료 시간 (30분)

// 만료된 캐시 정리 함수
const cleanupExpiredCache = async () => {
  try {
    const cache = await caches.open(cacheName);
    const requests = await cache.keys();

    for (const request of requests) {
      const cachedResponse = await cache.match(request);
      if (cachedResponse) {
        const cachedData = await cachedResponse.json();
        const now = Date.now();

        if (
          cachedData?.timestamp &&
          now - cachedData.timestamp >= cacheExpiryTime
        ) {
          await cache.delete(request);
        }
      }
    }
  } catch (error) {
    console.error("캐시 정리 중 오류 발생:", error);
  }
};

// 캐시에서 데이터 가져오기 함수
const getCachedData = async (
  cacheKey: string
): Promise<ReadNormalBoardItem | undefined> => {
  try {
    const cache = await caches.open(cacheName);
    const cachedResponse = await cache.match(cacheKey);

    if (cachedResponse) {
      const cachedData = await cachedResponse.json();
      const now = Date.now();

      if (
        cachedData?.timestamp &&
        now - cachedData.timestamp < cacheExpiryTime
      ) {
        return cachedData.data;
      } else {
        console.info(`캐시 만료됨, 삭제 중: ${cacheKey}`);
        await cache.delete(cacheKey);
      }
    }
  } catch (error) {
    console.error("캐시 데이터 가져오기 중 오류 발생:", error);
  }
  return undefined;
};

// 캐시에 데이터 저장 함수
const cacheData = async (
  cacheKey: string,
  data: ReadNormalBoardItem
): Promise<void> => {
  try {
    const now = Date.now();
    const cacheContent = { data, timestamp: now };
    const response = new Response(JSON.stringify(cacheContent));
    const cache = await caches.open(cacheName);

    await cache.put(cacheKey, response);
    console.info(`캐시에 데이터 저장 완료: ${cacheKey}`);
  } catch (error) {
    console.error("캐시 데이터 저장 중 오류 발생:", error);
  }
};

// useDetailQuery 훅
export const useDetailQuery = <T extends ReadNormalBoardItem | undefined>(
  url: string,
  PostId: string
) => {
  // 주기적으로 만료된 캐시 정리
  useEffect(() => {
    const intervalId = setInterval(cleanupExpiredCache, 5 * 60 * 1000); // 5분마다 정리
    return () => clearInterval(intervalId); // 컴포넌트 언마운트 시 인터벌 정리
  }, []);

  return useQuery<T, Error>({
    queryKey: [url, PostId],
    queryFn: async () => {
      const cacheKey = `${url}-${PostId}`;

      // 캐시에서 데이터 가져오기
      const cachedData = await getCachedData(cacheKey);
      if (cachedData) {
        return cachedData as T;
      }

      // 캐시에 데이터가 없는 경우 API 요청 후 저장
      const response = await controller.get({
        url,
        params: { PostId },
      });

      const resData = response as ApiResponse;
      if (resData.IsSuccess) {
        const item = resData.Message.ReadNormalBoardList.find(
          (item: ReadNormalBoardItem) => item.RowKey === PostId
        );

        if (item) {
          await cacheData(cacheKey, item); // 캐시에 데이터 저장
        }
        return item as T;
      } else {
        throw new Error("데이터를 불러올 수 없습니다.");
      }
    },
    staleTime: cacheExpiryTime, // React Query의 stale 상태 유지 시간
    cacheTime: cacheExpiryTime, // React Query의 캐시 유지 시간
  } as UseQueryOptions<T, Error>);
};
