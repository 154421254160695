import React, { ReactNode } from "react";
import { Helmet } from "react-helmet-async";
import titleImg from "../../assets/titleImg/small.png";
import titleLogo from "../../assets/logo/logoTitle.png";
import SideBar from "../SideBar";
import ScrollTopButton from "../ScrollTopButton";

interface LayoutProps {
  title: string;
  children?: ReactNode;
  img?: string;
}

const DetailLayout: React.FC<LayoutProps> = ({ title, children, img }) => {
  const ogImage = img
    ? `https://mizcamping.samplewebpage.store/${img}`
    : `${titleLogo}`;

  return (
    <>
      <Helmet>
        <title>미즈캠핑자연인 - {title}</title>
        <meta name="description" content={`Details about ${title}`} />
        <meta property="og:title" content={`미즈캠핑자연인 - ${title}`} />
        <meta property="og:description" content={`Details about ${title}`} />
        <meta property="og:image" content={ogImage} />
      </Helmet>

      <SideBar />
      <ScrollTopButton />
      <div className="flex flex-col bg-[#e0efff] justify-center items-center">
        <div className="flex flex-col w-full h-[300px] justify-center items-center relative">
          <div className="relative w-full h-full overflow-hidden z-0">
            <img
              src={titleImg}
              alt="title"
              className="object-cover w-full h-full"
            />
            <div className="absolute inset-0 flex flex-row justify-center items-center bg-black bg-opacity-50">
              <p className="text-white text-4xl font-bold xs:text-3xl sm:text-3xl">
                {title}
              </p>
              <img src={titleLogo} alt="title" className="pb-4 h-[80px]" />
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full sm:w-[80%] justify-center items-center pb-10">
          <div className="flex flex-col bg-white sm:p-10 rounded-lg shadow-lg justify-center items-center z-0 xs:w-full pt-[10px] xs:pt-[80px] sm:pt-[80px] md:pt-[100px] lg:pt-[100px]">
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailLayout;
