import ErrorIcon from "@mui/icons-material/Error";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col bg-[#e0efff] justify-center items-center p-10 h-[400px]">
      <div className="flex flex-col bg-white p-10 rounded-lg shadow-lg justify-center items-center">
        <ErrorIcon />
        <p>죄송합니다. 해당 페이지를 찾지 못했습니다.</p>
        <Button variant="outlined" onClick={() => navigate(`/`)}>
          홈으로
        </Button>
      </div>
    </div>
  );
};

export default ErrorPage;
