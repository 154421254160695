import { QueryClient, QueryClientConfig } from "@tanstack/react-query";

export type ExtendedQueryClientConfig = QueryClientConfig & {
  defaultOptions?: {
    queries?: {
      staleTime?: number;
      cacheTime?: number;
      refetchOnWindowFocus?: boolean;
      refetchOnReconnect?: boolean;
    };
  };
};

// QueryClient 설정 포함
const queryClientConfig: ExtendedQueryClientConfig = {
  defaultOptions: {
    queries: {
      staleTime: 30 * 60 * 1000,
      cacheTime: 30 * 60 * 1000,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
};

export const queryClient = new QueryClient(queryClientConfig);
