import React, { useEffect, useState } from "react";
import CallModal from "../CallModal";
import CallIcon from "@mui/icons-material/Call";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BannerResponse, JsonValue } from "../../types/apiResponseTypes";
import { InfoSliderItem } from "../../types/bannerItemTypes";

const Info: React.FC = () => {
  const [data, setData] = useState<InfoSliderItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  const fetchData = async () => {
    const API_URL = `https://aurorafucntion.azurewebsites.net/api/ReadEnvironment?Company=mizcamping&Type=mainbanner&code=7o6gsQV5xRINbEu5LM6rEPtfugrE5axR1B6Hox5q3punAzFuxFIP6w==`;

    try {
      const response = await axios.get<BannerResponse>(API_URL);

      const resData = response.data;

      if (resData.IsSuccess) {
        const parsedJsonValue: JsonValue = JSON.parse(
          resData.Message.JsonValue
        );

        const sliderData: InfoSliderItem[] = parsedJsonValue.BannerItems.map(
          (item) => ({
            src: item.ImgUrl,
            alt: item.Title,
            text: item.Title,
          })
        );

        setData(sliderData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      navigate(`/Error`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleItemClick = (src: string) => {
    const [partitionKey, rowKey] = src.split("/").slice(0, 2);
    navigate(`/${partitionKey}/${rowKey}`);
  };

  return (
    <>
      <div className="flex h-20 items-center justify-center lg:justify-start">
        <p
          className="text-[#213260] font-bold cursor-pointer text-lg xs:text-lg sm:text-2xl md:text-3xl lg:text-5xl lg:pl-10 pt-10"
          onClick={() => setModalOpen(true)}
        >
          <CallIcon sx={{ fontSize: 40, color: "#0F378D" }} /> 상담전화
          010.4157.3478
        </p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-8 pt-16 pb-6">
        {data.map(({ src, alt, text }: InfoSliderItem, index: number) => (
          <div key={index}>
            <div
              className="bg-blue-50 rounded-lg shadow flex flex-col items-center justify-center hover:scale-105 h-[220px] xl:h-[340px]"
              onClick={() => handleItemClick(src)}
            >
              <img
                src={`${imageBaseUrl}/${src}`}
                alt={alt}
                className="rounded-lg h-3/4 w-full object-fill p-3"
              />
              <p className="text-base h-1/4 mt-4 xs:text-lg sm:text-base md:text-xl lg:text-lg xl:text-2xl text-center">
                {text}
              </p>
            </div>
          </div>
        ))}
      </div>

      <CallModal open={modalOpen} handleClose={() => setModalOpen(false)} />
    </>
  );
};

export default Info;
