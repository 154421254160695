import { useState, useEffect } from "react";
import { AxiosController } from "../../api/axiosController";
import { ApiResponse } from "../../types/apiResponseTypes";
import DetailLayout from "../../components/layout/DetailLayout";
import DetailList from "../../components/DetailList";
import categoryItems from "../../data/categoryItems";
import { useNavigate, useSearchParams } from "react-router-dom"; // useSearchParams 추가
import { CircularProgress, Pagination } from "@mui/material";
import { ReadNormalBoardItem } from "../../types/ReadNormalBoardItem";

const Indian: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams(); // 쿼리스트링 관리
  const [data, setData] = useState<ReadNormalBoardItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [totalCount, setTotalCount] = useState(0);

  // URL에서 페이지 번호를 가져옴, 없으면 기본적으로 1번 페이지
  const pageFromURL = parseInt(searchParams.get("page") || "1", 10);
  const [currentPage, setCurrentPage] = useState(pageFromURL);

  const controller = new AxiosController();

  const fetchData = async (categoryType: string, page: number) => {
    try {
      const url =
        categoryType === "인디언, 게르 텐트"
          ? "Indian"
          : "Installation/인디언텐트 설치사례";

      const response = await controller.get({
        url,
        params: {
          PageNo: page,
        },
      });
      const resData = response as ApiResponse;

      if (resData.IsSuccess) {
        setData(resData.Message.ReadNormalBoardList);
        setTotalCount(resData.Message.TotalCount ?? 0);
      }
    } catch (error) {
      navigate("/Error");
    } finally {
      setLoading(false);
    }
  };

  const handleCategory = (item: string, index: number) => {
    setActiveIndex(index);

    setCurrentPage(1); // 카테고리 변경 시 첫 페이지로 초기화
    setSearchParams({ page: "1" }); // 쿼리스트링도 첫 페이지로 설정

    if (item === "설치사례") {
      navigate(`/Installation/인디언, 게르 텐트 설치사례`);
    }
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page);
    setSearchParams({ page: String(page) }); // 페이지 변경 시 URL에 반영
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    fetchData("인디언, 게르 텐트", currentPage);
  }, [currentPage]);

  return (
    <DetailLayout title="인디언, 게르 텐트">
      <div className="relative top-[-140px] w-full flex justify-center items-center z-40 rounded-lg pt-2 xxs:h-[90px] xs:h-[90px] sm:h-[90px] md:h-[100px] lg:h-[100px]">
        <div className="flex flex-row w-[70%] h-full text-center rounded-lg justify-evenly items-center xxs:text-xs xs:text-xs sm:text-sm md:text-md lg:text-xl">
          {categoryItems["인디언게르텐트"].list.map((item, index) => (
            <p
              key={index}
              className={`flex justify-center items-center h-full rounded-2xl shadow-lg text-base xs:text-base sm:text-xl md:text-xl lg:text-2xl ${
                activeIndex === index
                  ? "text-black bg-blue-200 w-[50%]"
                  : "bg-white w-[50%] hover:bg-blue-200"
              }`}
              onClick={() => handleCategory(item, index)}
            >
              {item}
            </p>
          ))}
        </div>
      </div>

      <div className="flex justify-center w-full">
        {loading ? (
          <div>
            로딩 중...
            <CircularProgress size="2rem" />
          </div>
        ) : (
          <div className=" flex flex-col relative top-[-100px] justify-center items-center w-full">
            <DetailList
              data={data}
              title={categoryItems["인디언게르텐트"].list[activeIndex]}
            />
            <Pagination
              count={Math.ceil(totalCount / 12)}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              variant="outlined"
              shape="rounded"
              sx={{ marginTop: "30px" }}
            />
          </div>
        )}
      </div>
    </DetailLayout>
  );
};

export default Indian;
