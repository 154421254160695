import * as React from "react";
import Modal from "@mui/material/Modal";
import titleLogo from "../assets/logo/removeWhite.png";

interface CallModalProps {
  open: boolean;
  handleClose: () => void;
}

const CallModal: React.FC<CallModalProps> = ({ open, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="call-modal-title"
      aria-describedby="call-modal-description"
    >
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white rounded-lg shadow-lg w-96 p-6">
          <img src={titleLogo} alt="title" className="pb-4 " />
          {/* <h2 id="call-modal-title" className="text-lg font-bold">
            상담 문의 전화
          </h2> */}

          <p id="call-modal-description" className="mt-2">
            전화를 걸까요?
          </p>
          <div className="mt-4 flex justify-end">
            <button
              onClick={() => {
                window.location.href = "tel:010-4157-3478"; // 전화 걸기
                handleClose();
              }}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
            >
              전화하기
            </button>
            <button
              onClick={handleClose}
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded ml-2 hover:bg-gray-400 transition"
            >
              취소
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CallModal;
